@import '../../../styles/_fonts.scss';


.container {
  @extend %miller;
  width: 128px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9BF09;
  border-radius: 18px;
  color: #1A1A1A;
  cursor: pointer;
  outline: none;
  user-select: none;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    background-color: #429B57;
  }

  &:active {
    background-color: #429B57;
  }
}

.disabled {
  cursor: default;
  background-color: #7B7B7B;

  &:hover {
    background-color: #7B7B7B;
  }

  &:active {
    background-color: #7B7B7B;
  }
}
