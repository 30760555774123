@import '../../../styles/_fonts.scss';
@import '../../../styles/_media_queries.scss';


.container {
  @media (max-width: $xl-mobile) {
    width: 100%;
  }

  .header {
    @extend %miller;
    font-size: 24px;
    font-weight: 700;
    color: #F3E6DE;
    margin-bottom: 28px;

    @media (max-width: $mobile) {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  .thankYou {
    @extend %yantra;
    font-size: 16px;
    color: #F3E6DE;

    @media (max-width: $mobile) {
      font-size: 14px;
    }
  }

  .instagramLink {
    @extend %yantra;
    font-size: 16px;
    text-decoration: none;
    color: #F9BF09;
  }
}
