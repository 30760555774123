@import '../../../styles/_media_queries.scss';

.view {
  width: 100%;
  height: 100%;
  position: relative;

  .logo {
    max-width: 180px;
    position: absolute;
    margin-left: 48px;
    margin-top: 60px;
    z-index: 10;

    @media (max-width: $mobile) {
      max-width: 136px;
      margin-top: 24px;
      margin-left: 20px;
    }
  }

  .bgImg {
    width: 100vw;
    position: absolute;
  }

  .imgOverlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.85) 65%);
    z-index: 5;

    @media (max-width: $mobile) {
      background: radial-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.85) 65%);
    }
  }

  .infoContainer {
    position: absolute;
    bottom: 72px;
    margin-left: 48px;
    z-index: 10;
    color: white;

    @media (max-width: $mobile) {
      padding-right: 20px;
    }

    @media (max-width: $mobile) {
      margin-left: 20px;
      bottom: 72px;
    }

    .playButton {
      cursor: pointer;
      margin-bottom: 36px;
      outline: none;

      @media (max-width: $mobile) {
        width: 48px;
        margin-bottom: 24px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .title {
      font-size: 32px;
      font-weight: 500;
    }

    .description {
      font-size: 16px;
    }
  }
}
