@import '../../styles/_media_queries.scss';


.container {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  display: flex;
  justify-content: center;

  .splitView {
    max-width: 1280px;
    width: 100%;
    display: flex;

    @media (max-width: $ipad) {
      flex-direction: column;
    }

    .leftSide {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $mobile) {
        padding-top: 20px;
      }
    }

    .rightSide {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $ipad) {
        align-items: flex-start;
      }

      @media (max-width: $xl-mobile) {
        padding: 0 20px;
      }
    }
  }

  .close {
    position: absolute;
    top: 60px;
    right: 48px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    outline: none;

    @media (max-width: $ipad) {
      top: 24px;
    }

    @media (max-width: $mobile) {
      right: 20px;
    }

    &:hover {
      color: #F9BF09;
    }

    .closeText {
        margin-right: 8px;
     }
  }
}
