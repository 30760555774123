@import '../../../styles/_fonts.scss';
@import '../../../styles/_media_queries.scss';


.title {
  @extend %miller;
  max-width: 688px;
  font-size: 54px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 12px;

  @media (max-width: $mobile) {
    font-size: 28px;
  }
}

.description {
  @extend %yantra;
  max-width: 520px;
  font-size: 16px;
  font-weight: 300;
  color: #F3E6DE;

  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

.divider {
  width: 1px;
  height: 12px;
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #F3E6DE;
}

.stats {
  display: flex;
  align-items: flex-end;
  margin-top: 36px;

  @media (max-width: $mobile) {
    margin-top: 20px;
  }

  .count {
    @extend %miller;
    font-size: 90px;
    font-weight: 700;
    line-height: 71px;
    margin-right: 20px;

    @media (max-width: $mobile) {
      font-size: 40px;
      line-height: 1;
      margin-right: 12px;
    }
  }

  .choice {
    @extend %yantra;
    max-width: 280px;
    font-size: 16px;
    font-weight: 300;
    color: #F3E6DE;

    @media (max-width: $mobile) {
      font-size: 14px;
    }
  }
}

.pink {
  color: #EEACB0;
}

.green {
  color: #429B57;
}

.red {
  color: #E74C24;
}

.yellow {
  color: #F9BF09;
}
