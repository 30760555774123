@import '../styles/_fonts.scss';
@import '../styles/_media_queries.scss';


.largeLogo {
  margin-left: 48px;

  @media (max-width: $mobile) {
    margin-left: 20px;
  }
}

.logo {
  position: absolute;
  max-width: 200px;
  top: 60px;
  left: 48px;

  @media (max-width: $mobile) {
    max-width: 136px;
    top: 24px;
    left: 20px;
  }
}

.fullContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .itemContainer {
    max-width: 1024px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px;

    @media (max-width: $mobile) {
      padding: 20px;
    }

    .heads {
      width: 80%;

      @media (max-width: $xl-mobile) {
        margin-top: 72px;
      }
    }

    .text {
      @extend %miller;
      font-size: 48px;
      font-weight: 700;
      color: #F3E6DE;

      @media (max-width: $ipad) {
        font-size: 36px;
      }

      @media (max-width: $xl-mobile) {
        margin-top: 16px;
        font-size: 28px;
      }

      @media (max-width: $mobile) {
        margin-top: 16px;
        font-size: 20px;
      }
    }

    .firstLine {
    }

    .highlight {
      display: inline-block;
    }

    .pink {
      color: #EEACB0;
    }

    .green {
      color: #429B57;
    }

    .red {
      color: #E74C24;
    }

    .yellow {
      color: #F9BF09;
    }

    .link {
      @extend %yantra;
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-top: 48px;
      text-decoration: none;
      cursor: pointer;
      color: #EEACB0;

      &:hover {
        color: #f9BF09;
      }
    }

    .next {
      margin-left: 12px;
      height: 20px;
    }
  }
}
