font-face {
  font-family: 'Yantramanav';
  src: local('Yantramanav'), url(./fonts/Yantramanav-Regular.ttf) format('ttf');
}

font-face {
  font-family: 'Miller';
  src: local('Miller'), url(./fonts/MillerDisplay-Bold.ttf) format('ttf');
}


%miller {
  font-family: 'Miller', serif;
}

%yantra {
  font-family: 'Yantramanav', san-serif;
}
