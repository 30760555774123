@import '../../../styles/_media_queries.scss';
@import '../../../styles/_fonts.scss';


.navigation {
  @extend %yantra;
  font-weight: 600;
  width: 200px;
  position: absolute;
  bottom: 72px;
  right: 48px;
  display: flex;
  justify-content: space-between;
  z-index: 100;

  @media (max-width: $mobile) {
    width: calc(100% - 40px);
    left: 20px;
    bottom: 24px;
  }

  .previous {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    outline: none;

    &:hover {
      color: #F9BF09;
    }

    .previousArrow {
      margin-right: 8px;
    }
  }

  .next {
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    outline: none;

    &:hover {
      color: #F9BF09;
    }

    .nextArrow {
      margin-left: 8px;
    }
  }
}
